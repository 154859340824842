import "./SalmonSoftwareInsight.scss";

import React, { FunctionComponent } from "react";
import { HeadProps, Link } from "gatsby";
import Layout from "../../components/core/Layout";
import WidgetBuilder from "../../components/builders/WidgetBuilder";
import PuxMediaSelector from "../../components/contentParts/mediaImage/PuxMediaSelector";
import PuxWysiwyg from "../../components/contentTypes/PuxWysiwyg/PuxWysiwyg";
import Breadcrumbs from "../../components/core/Breadcrumbs";
import { getLocalizedUrl } from "../../utils/localeURL";
import SalmonSoftwareInsightCard from "../../components/contentTypes/SalmonSoftwareInsightCard/SalmonSoftwareInsightCard";
import { SalmonSoftwareInsightType } from "Root/src/queries/page-queries";
import { IPageContext } from "Root/src/utils/data/buildTypedPage";
import PuxMetaTagsGatsby from "Root/src/utils/PuxMetaTagsGatsby";

const SalmonSoftwareInsight: FunctionComponent<SalmonSoftwareInsightType> = ({
  data,
  pageContext,
}) => {
  const insight = pageContext.pageData.insight[0];
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData
  const relatedInsights = pageContext.pageData.relatedInsights;

  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
    >
      <Breadcrumbs items={pageContext.breadcrumbs} />
      <div className="Insight">
        <div className="PuxContainer">
          <div className="Insight-grid">
            <div className="Insight-header">
              <h1 className="u-h1">{insight.displayText}</h1>
              <div className="Insight-links">
                <div className="Insight-labels">
                  <Link
                    to={
                      getLocalizedUrl(insight.puxCategory?.termContentItems[0]?.categoryContentLink?.url[0])
                    }
                  >
                    {
                      insight.puxCategory?.termContentItems[0]?.displayText
                    }
                  </Link>
                  {insight.insightLabel?.termContentItems.map((termContentItem, idx) => {
                    if (termContentItem) {
                      return (
                        <Link key={idx} className="Insight--label" to={getLocalizedUrl(termContentItem.labelContentLink?.url[0])}>
                          {termContentItem.displayText}
                        </Link>
                      )
                    }
                    return
                  })}
                </div>
              </div>
            </div>

            <div className="Insight-content">
              {!insight.dontShowPreviewImage && (
                <div className="Insight-image">
                  <PuxMediaSelector {...insight.insightImage} />
                </div>
              )}
              <PuxWysiwyg className="perex" content={insight.insightPerex} />
              <WidgetBuilder
                widgetBuilderData={pageContext?.widgets?.insightContentWidgets}
              />
              {(insight.insightTwitterLink || insight.insightLinkedInLink || insight.insightXiLink) && (
                <div className="Insight-logo">
                  <span>Share this article</span>
                  <ul className="Social-links">
                    {insight.insightTwitterLink && (
                      <li>
                        <a className="Social-logo tw--logo" href={insight.insightTwitterLink}></a>
                      </li>
                    )}
                    {insight.insightLinkedInLink && (
                      <li>
                        <a className="Social-logo in--logo" href={insight.insightLinkedInLink}></a>
                      </li>
                    )}
                    {insight.insightXiLink && (
                      <li>
                        <a className="Social-logo xing--logo" href={insight.insightXiLink}></a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>

            <aside className="RelatedInsights">
              <div className="RelatedInsights-wrapper">
                <h4 className="u-h4">Related articles</h4>
                <div className="RelatedInsights-grid">
                  {relatedInsights.map((relatedInsight, idx) => (
                    <SalmonSoftwareInsightCard key={idx} isSmallCard {...relatedInsight} />
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
        <WidgetBuilder widgetBuilderData={pageContext?.widgets?.flow} />
      </div >
    </Layout >
  );
};

export default SalmonSoftwareInsight;

export function Head(props: HeadProps<never, IPageContext<SalmonSoftwareInsightType>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}