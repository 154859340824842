export interface IGetRepeaterQuery {
  contentType: RepeaterContentType
  itemIdsArray: string[],
  limit: number
  sort: boolean
  preferedContentLinkCulture: string
}

export type RepeaterContentType =
  "SalmonSoftwareFaq" |
  "SalmonSoftwareInsight"

export const getRepeaterQuery = ({
  contentType,
  itemIdsArray,
  limit,
  sort,
  preferedContentLinkCulture = ""
}: IGetRepeaterQuery) => {
  const itemIdsString = itemIdsArray.map((item) => `"${item}"`).join(`,`)

  const queryImagePaths = `
  mediaSelectorImage {
    resizePaths(width: 1920, mode: "Min", quality: 80)
    resizePathsMobile(width: 768, mode: "Min", quality: 80)
    resizePathsTablet(width: 1200, mode: "Min", quality: 80)
    texts
  }
`

  switch (contentType) {
    case `SalmonSoftwareFaq`:
      return `
        salmonSoftwareFaq(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          displayText
          faqAnswer {
            html
          }
        }
      `

    case `SalmonSoftwareInsight`:
      return `
        salmonSoftwareInsight(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          path
          displayText
          puxMetaTags {
            metaTagsDescription {
              html
            }
          }
          insightPerex {
            html
          }
          createdUtc
          insightType
          insightLabel {
            termContentItems {
              ... on Orchard_PuxLabel {
                displayText
                labelContentLink {
                  internal
                  text
                  url(culture: "${preferedContentLinkCulture}")
                }
                puxFilterItemName
              }
            }
          }
          puxCategory {
            termContentItems {
              ... on Orchard_PuxCategory {
                displayText
                categoryContentLink {
                  internal
                  text
                  url(culture: "${preferedContentLinkCulture}")
                }
              }
            }
          }
          insightImage {
            ${queryImagePaths}
          }
        }
      `

    default:
      return ``
  }
}
