export const getWidgetQuery = (key: string, preferedContentLinkCulture = ""): string => {
  const queryWidgetProperties = `
    puxWidgetProperties {
      widgetPropertiesID
      widgetPropertiesMarginBottom
      widgetPropertiesMarginTop
      widgetPropertiesPaddingBottom
      widgetPropertiesPaddingTop
    }
    metadata {
      alignment
      size
    }
  `;

  const queryButtonProperties = `
    buttonLink {
      internal
      text
      url
    }
    buttonNewWindow
    buttonOpenModalId
    buttonType
  `;

  const queryImagePaths = `
    mediaSelectorImage {
      resizePaths(width: 1920, mode: "Min", quality: 80)
      resizePathsMobile(width: 768, mode: "Min", quality: 80)
      resizePathsTablet(width: 1200, mode: "Min", quality: 80)
      texts
    }
  `;

  // const queryImageBoxList = `
  //   contentItems {
  //     ... on Orchard_PuxImageBox {
  //       contentType
  //       contentItemId
  //       displayText
  //       imageBoxContent {
  //         html
  //       }
  //       imageBoxImage {
  //         mediaSelectorImage {
  //           ${queryImagePaths}
  //         }
  //         mediaSelectorLightbox
  //       }
  //       imageBoxButton {
  //         ${queryButtonProperties}
  //       }
  //     }
  //   }
  // `

  // const queryMediaVideoProperties = `
  //   file {
  //     paths
  //   }
  //   muted
  //   autoplay
  //   loop
  //   controls
  //   width
  //   height
  // `

  const queryPuxVideoProperties = `
    videoStructuredDataContentUrl
    videoStructuredDataDescription
    videoStructuredDataName
    videoStructuredDataUploadDate
    videoThumbnail {
      resizePaths(width: 1920, mode: "Min", quality: 80)
      resizePathsMobile(width: 768, mode: "Min", quality: 80)
      resizePathsTablet(width: 1200, mode: "Min", quality: 80)
    }
    videoYTID {
      html
    }
  `;

  switch (key) {
    case `PuxWysiwyg`:
      return `
          ... on Orchard_PuxWysiwyg {
            contentType
            contentItemId
            wysiwygSmallContainer
            htmlBody {
              html
            }
            ${queryWidgetProperties}
          }
        `;

    case `SalmonSoftwareTopImageContainer`:
      return `
        ... on Orchard_SalmonSoftwareTopImageContainer {
          displayText
          contentItemId
          contentType
          sliderArrows
          sliderAutoPlay
          sliderDots
          ${queryWidgetProperties} 
          topImageContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareTopImage {
                contentItemId
                displayText
                topImagePerex {
                  html
                }
                topImageBackground {
                  ${queryImagePaths}
                }
                topImagePrimaryButton {
                  ${queryButtonProperties}
                }
              }
            }
          }
        }
      `;

    case `SalmonSoftwareVideoBanner`:
      return `
        ... on Orchard_SalmonSoftwareVideoBanner {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          videoBannerContent {
            html
          }
          videoBannerPrimaryButton {
            ${queryButtonProperties}
          }
          videoBannerSecondaryButton {
            ${queryButtonProperties}
          }
          puxVideo {
            ${queryPuxVideoProperties}
          }
        }
      `;

    case `SalmonSoftwarePersonsContainer`:
      return `
        ... on Orchard_SalmonSoftwarePersonsContainer {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties} 
          personsContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwarePerson {
                contentItemId
                displayText
                personJob
                personPhoto {
                  ${queryImagePaths}
                }
              }
            }
          }
        }
      `;

    case `SalmonSoftwareBenefitsContainer`:
      return `
        ... on Orchard_SalmonSoftwareBenefitsContainer {
          displayText
          contentItemId
          contentType
          puxButton {
            ${queryButtonProperties}
          }
          ${queryWidgetProperties}
          benefitsContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareBenefit {
                contentItemId
                displayText
                benefitContent {
                  html
                }
                benefitIcon {
                  ${queryImagePaths}
                }
              }
            }
          }
        }
      `;

    case `SalmonSoftwareTestimonialsContainer`:
      return `
        ... on Orchard_SalmonSoftwareTestimonialsContainer {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties} 
          testimonialsContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareTestimonial {
                contentItemId
                displayText
                testimonialJob
                testimonialName
                testimonialCompany
                testimonialContent {
                  html
                }
                testimonialPhoto {
                  ${queryImagePaths}
                }
              }
            }
          }
        }
      `;

    case `SalmonSoftwareFeaturesContainer`:
      return `
        ... on Orchard_SalmonSoftwareFeaturesContainer {
          displayText
          featuresContainerContent {
            html
          }
          contentItemId
          contentType
          ${queryWidgetProperties} 
          featuresContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareFeature {
                contentItemId
                featureDescription
                featureContent {
                  html
                }
                salmonSoftwareIcon {
                  iconSelector
                }
                featureLink {
                  contentItems {
                    ... on Orchard_PuxLandingPage {
                      path
                    }
                  }
                }
              }
            }
          }
        }
      `;

    case `SalmonSoftwareAccordionsContainer`:
      return `
        ... on Orchard_SalmonSoftwareAccordionsContainer {
          displayText
          accordionsContainerContent {
            html
          }
          accordionsContainerInterval
          contentItemId
          contentType
          ${queryWidgetProperties} 
          accordionsContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareAccordion {
                contentItemId
                accordionDescription
                accordionContent {
                  html
                }
                accordionImage {
                  ${queryImagePaths}
                }
                accordionLink {
                  contentItems {
                    ... on Orchard_PuxLandingPage {
                      path
                    }
                  }
                }
                accordionLinkText
              }
            }
          }
        }
      `;

    case `SalmonSoftwareHeroVideo`:
      return `
        ... on Orchard_SalmonSoftwareHeroVideo {
          displayText
          htmlBody {
            html
          }
          contentItemId
          contentType
          ${queryWidgetProperties} 
          puxButton {
            ${queryButtonProperties}
          }
          heroVideoAutoplay
          heroVideoVideo {
            paths
          }
        }
      `;

    case `SalmonSoftwareCTABanner`:
      return `
        ... on Orchard_SalmonSoftwareCTABanner {
          displayText
          cTABannerContent {
            html
          }
          cTABannerButton {
            ${queryButtonProperties}
          }
          cTABannerImage {
            ${queryImagePaths}
          }
          contentItemId
          contentType
          ${queryWidgetProperties} 
        }
      `;

    case `SalmonSoftwareCrossroad`:
      return `
        ... on Orchard_SalmonSoftwareCrossroad {
          displayText
          contentItemId
          contentType
          crossroadItems {
            contentItems {
              ... on Orchard_SalmonSoftwareCategory {
                displayText
                path
                salmonSoftwareIcon {
                  iconSelector
                }
              }
            }
          }
          ${queryWidgetProperties} 
        }
      `;

    case `PuxRepeater`:
      return `
        ... on Orchard_PuxRepeater {
          contentType
          contentItemId
          repeaterPageSize
          repeaterPagerOptions
          repeaterOnePageOnly
          repeaterFallbackCulture {
            html
          }
          puxFilter {
            filterTermIDs {
              termContentItemIds
            }
            filterExplicitIDs {
              cultureContentItems(culture: "${preferedContentLinkCulture}") {
                contentItemId
              }
            }
            filterContentType
            filterSortByIDs
            puxFilterIsHidden
          }
          ${queryWidgetProperties}
        }
      `;

    case `PuxImageContent`:
      return `
        ... on Orchard_PuxImageContent {
          contentItemId
          contentType
          imageContentText {
            html
          }
          imageContentImagePosition
          imageContentImage {
            ${queryImagePaths}
          }
          imageContentButton {
            ${queryButtonProperties}
          }
          ${queryWidgetProperties}
        }
      `;

    case `SalmonSoftwareVideo`:
      return `
          ... on Orchard_SalmonSoftwareVideo {
            displayText
            contentItemId
            contentType
            ${queryWidgetProperties}
            puxVideo {
              ${queryPuxVideoProperties}
            }
          }
        `;
    case `SalmonSoftwareGalleryContainer`:
      return `
        ... on Orchard_SalmonSoftwareGalleryContainer {
          displayText
          contentItemId
          contentType
          ${queryWidgetProperties}
          galleryContainerItems {
            contentItems {
              ... on Orchard_SalmonSoftwareGallery {
                bag {
                  contentItems {
                    ... on Orchard_SalmonSoftwareGalleryItem {
                      galleryItemUrl
                      galleryItemImage {
                        ${queryImagePaths}
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `;
    case `SalmonSoftwareImageFromLibrary`:
      return `
      ... on Orchard_SalmonSoftwareImageFromLibrary {
        displayText
        imageFromLibraryDescription
        contentType
        contentItemId
        imageFromLibraryImage {
          ${queryImagePaths}
        }
        ${queryWidgetProperties}
      }
      `

    default:
      return ``;
  }
};
