import { PageProps } from 'gatsby'
import { IPageContext } from "../utils/data/buildTypedPage"
import { SalmonSoftwareContactPageType } from '../templates/SalmonSoftwareContactPage/SalmonSoftwareContactPage'
import { SalmonSoftwareInsightType } from '../templates/SalmonSoftwareInsight/SalmonSoftwareInsight'
import { SalmonSoftwareSearchResultsType } from '../templates/SalmonSoftwareSearchResults/SalmonSoftwareSearchResults'
import { PuxMetaTagsType, localizedPathType } from '../utils/PuxMetaTagsGatsby'
import { WidgetPuxSectionType } from '../components/widgets/WidgetPuxSection'
import { IFooterData, IHeaderData } from './layout-queries'
import { BreadcrumbItem } from '../components/core/Breadcrumbs'
import { PuxMediaSelectorType } from '../components/contentParts/mediaImage/PuxMediaSelector'
import { PuxWysiwygHtmlBodyType } from '../components/contentTypes/PuxWysiwyg/PuxWysiwyg'
import { SalmonSoftwareContactItemType } from '../components/contentTypes/SalmonSoftwareContactItem/SalmonSoftwareContactItem'

export type PageContentType =
  "PuxLandingPage" |
  "SalmonSoftwareContactPage" |
  "SalmonSoftwareInsight" |
  "SalmonSoftwareCategory" |
  "SalmonSoftwareSearchResults"

export type PageType =
  PuxLandingPageType |
  SalmonSoftwareInsightType |
  SalmonSoftwareCategoryType |
  SalmonSoftwareContactPageType |
  SalmonSoftwareSearchResultsType

export interface GenericPageProps<T> extends PageProps {
  pageContext: IPageContext<T>
}

export interface BasePage {
  contentType: string
  contentItemId: string
  contentItemVersionId?: string
  displayText: string
  path: string
  localization: {
    localizations: localizedPathType[]
  }
  puxMetaTags: PuxMetaTagsType
  puxCategory: {
    taxonomyContentItemId: string
    termContentItemIds: string[]
  }
}

export interface LandingPageType {
  localization: {
    // localizations: localizedPathType[]
  }
}

export interface PuxLandingPageType extends BasePage {
  data: {
    orchard: {
      puxLandingPage: [LandingPageType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      flow: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    footerData: IFooterData;
    headerData: IHeaderData;
  }
}

export interface CategoryPageType {
  displayText: string;
  categoryImage: PuxMediaSelectorType;
  categoryContent: PuxWysiwygHtmlBodyType;
  localization: {
    // localizations: localizedPathType[]
  };
}

export interface SalmonSoftwareCategoryType extends BasePage {
  data: {
    orchard: {
      salmonSoftwareCategory: [CategoryPageType];
    };
  };
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      flow: [WidgetPuxSectionType];
    };
    metaTags: PuxMetaTagsType;
    footerData: IFooterData;
    headerData: IHeaderData
  };
}

export interface ContactPageType {
  displayText: string;
  htmlBody: PuxWysiwygHtmlBodyType;
  path: string;
  googlemapApiKey: string;
  bag: {
    contentItems: SalmonSoftwareContactItemType[];
  };
  localization: {
    // localizations: localizedPathType[]
  };
}

export interface SalmonSoftwareContactPageType extends BasePage {
  data: {
    orchard: {
      salmonSoftwareContactPage: [ContactPageType];
    };
  };
  pageContext: {
    breadcrumbs: [BreadcrumbItem];
    metaTags: PuxMetaTagsType;
    footerData: IFooterData;
    headerData: IHeaderData;
  };
  bag: {
    contentItems: SalmonSoftwareContactItemType[];
  };
}

export interface InsightPageType {
  contentType: "SalmonSoftwareInsight";
  contentItemId?: string;
  displayText: string;
  dontShowPreviewImage: boolean;
  insightPerex: PuxWysiwygHtmlBodyType;
  insightImage: PuxMediaSelectorType;
  insightTwitterLink: string;
  insightLinkedInLink: string;
  insightXiLink: string;
  path: string;
  puxCategory: {
    taxonomyContentItem: {
      taxonomy: {
        contentItems: [
          {
            displayText: string;
            alias: {
              alias: string;
            };
          }
        ];
      };
    };
  };
  localization: {
    // localizations: localizedPathType[]
  };
}

export interface SalmonSoftwareInsightType extends BasePage {
  data: {};
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      flow: [WidgetPuxSectionType];
      insightContentWidgets: [WidgetPuxSectionType];
    };
    metaTags: PuxMetaTagsType;
    footerData: IFooterData;
    headerData: IHeaderData;
    pageData: {
      insight: [InsightPageType];
      relatedInsights: [InsightPageType];
    }
  };
}

export interface SearchResultsPageType {
  displayText: string;
  puxMediaSelector: PuxMediaSelectorType;
  searchResultsContent: PuxWysiwygHtmlBodyType;
  localization: {
    // localizations: localizedPathType[]
  };
}

export interface SalmonSoftwareSearchResultsType {
  data: {
    orchard: {
      salmonSoftwareSearchResults: [SearchResultsPageType];
    };
  };
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      flow: [WidgetPuxSectionType];
    };
    metaTags: PuxMetaTagsType;
    footerData: IFooterData;
    headerData: IHeaderData;
  };
}

export const getPageQuery = (
  contentType: PageContentType,
  pagePath?: string,
  isPreview: boolean = false
) => {

  switch (contentType) {
    case `PuxLandingPage`:
      return `
        query LandingPage {
          orchard {
            puxLandingPage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              localization {
                localizations {
                  ... on Orchard_PuxLandingPage {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `
    
    case `SalmonSoftwareContactPage`:
      return `
        query ContactPage {
          orchard {
            salmonSoftwareContactPage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              googlemapApiKey
              htmlBody {
                html
              }
              localization {
                localizations {
                  ... on Orchard_SalmonSoftwareContactPage {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              bag {
                contentItems {
                  ... on Orchard_SalmonSoftwareContactItem {
                    displayText
                    modifiedUtc
                    contactItemAddress {
                      html
                    }
                    contactItemLatitude
                    contactItemLongitude
                    contactItemPhone
                    contentType
                  }
                }
              }
            }
          }
        }
      `
    case `SalmonSoftwareInsight`:
      return `
        query Insight {
          orchard {
            insight: salmonSoftwareInsight(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              dontShowPreviewImage
              insightImage {
                mediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 80)
                  resizePathsMobile(width: 768, mode: "Min", quality: 80)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 80)
                  texts
                }
              }
              insightPerex {
                html
              }
              insightTwitterLink
              insightLinkedInLink
              insightXiLink
              localization {
                localizations {
                  ... on Orchard_SalmonSoftwareInsight {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              puxCategory {
                termContentItems {
                  ... on Orchard_PuxCategory {
                    displayText
                    categoryContentLink {
                      internal
                      text
                      url
                    }
                  }
                }
              }
              insightLabel {
                termContentItems {
                  ... on Orchard_PuxLabel {
                    displayText
                    labelContentLink {
                      internal
                      text
                      url
                    }
                    puxFilterItemName
                  }
                }
              }
            }
            relatedInsights: salmonSoftwareInsight(first: 3) {
              displayText
              createdUtc
              path
              puxCategory {
                termContentItems {
                  ... on Orchard_PuxCategory {
                    displayText
                    categoryContentLink {
                      internal
                      text
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `
    
    case `SalmonSoftwareCategory`:
      return `
        query Category {
          orchard {
            salmonSoftwareCategory(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              categoryImage {
                mediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 80)
                  resizePathsMobile(width: 768, mode: "Min", quality: 80)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 80)
                  texts
                }
              }
              categoryContent {
                html
              }
              localization {
                localizations {
                  ... on Orchard_SalmonSoftwareCategory {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `
    
    case `SalmonSoftwareSearchResults`:
      return `
        query SearchResults {
          orchard {
            salmonSoftwareSearchResults(first: 1, ${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              puxMediaSelector {
                mediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 80)
                  resizePathsMobile(width: 768, mode: "Min", quality: 80)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 80)
                  texts
                }
              }
              searchResultsContent {
                html
              }
              localization {
                localizations {
                  ... on Orchard_SalmonSoftwareSearchResults {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    default:
      return ``
  }
}