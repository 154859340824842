import { PageContentType } from "./page-queries"
import { getSectionsQuery } from "./section-queries"

export interface IGetBaseQuery {
  contentType: PageContentType
  sections: Object | string[] | undefined
  addPuxCategoryToQuery: boolean
  addMetaTags: boolean
  customBreadCrumbsTax: string | undefined
  isPreview?: boolean
}

export const getBaseQuery = ({
  contentType,
  sections,
  addPuxCategoryToQuery,
  addMetaTags,
  customBreadCrumbsTax,
  isPreview
}: IGetBaseQuery) => {
  const puxCategoryQuery = `
    puxCategory {
      taxonomyContentItemId
      termContentItemIds(first: 1)
    }
  `

  const customBreadCrumbsTaxQuery =
    customBreadCrumbsTax +
    ` {
      taxonomyContentItemId
      termContentItemIds(first: 1)
    }`

  const metaDataQuery = `puxMetaTags {
    metaTagsCanonicalUrl {
      html
    }
    metaTagsDescription {
      html
    }
    metaTagsImage {
      resizePaths(width: 1200, mode: "Min")
    }
    metaTagsKeywords {
      html
    }
    metaTagsOGDescription {
      html
    }
    metaTagsOGImage {
      resizePaths(width: 1200, mode: "Min")
    }
    metaTagsOGTitle {
      html
    }
    metaTagsRobots
    metaTagsTitle {
      html
    }
  }`

  // change first char in string to lowercase
  const contentTypeCamelCase = contentType.charAt(0).toLowerCase() + contentType.slice(1)

  return `{
    orchard {
      ${contentTypeCamelCase}%pathCondition% {
        path
        displayText
        contentType
        contentItemId
        ${isPreview ? `\ncontentItemVersionId\n` : ``}
        ${addPuxCategoryToQuery ? puxCategoryQuery : ``}
        ${customBreadCrumbsTax ? customBreadCrumbsTaxQuery : ``}
        ${addMetaTags ? metaDataQuery : ``}
        ${getSectionsQuery(sections)}
      }
    }
  }`
}