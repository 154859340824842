export const getSectionQuery = (section: string): string => {
  return `${section} {
    widgets {
      ... on Orchard_PuxSection {
        contentItemId
        contentType
        sectionUseContainer
        sectionModal
        puxBackgroundDefinition {
          backgroundDefinitionColor
        }
        puxWidgetProperties {
          widgetPropertiesID
          widgetPropertiesMarginTop
          widgetPropertiesPaddingTop
          widgetPropertiesMarginBottom
          widgetPropertiesPaddingBottom
        }
        puxWidgetAnimation {
          widgetAnimationPartType
          widgetAnimationPartIsEnabled
        }
        flow {
          widgets {
            %baseSectionQueryContentType%
            %widgetFragmentsPlaceholder%
          }
        }
      }
    }
  }`
}

export const getSectionsQuery = (sections) => {
  if (sections === undefined) return ``
  return sections.map(getSectionQuery).join(`\n`)
}