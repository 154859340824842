import React, { FunctionComponent } from 'react'
import WidgetBuilder from '../../components/builders/WidgetBuilder'
import Layout from '../../components/core/Layout'
import Breadcrumbs, { breadcrumbsOnTopImage } from '../../components/core/Breadcrumbs'
import { HeadProps } from 'gatsby'
import { IPageContext } from 'Root/src/utils/data/buildTypedPage'
import PuxMetaTagsGatsby from 'Root/src/utils/PuxMetaTagsGatsby'
import { PuxLandingPageType } from 'Root/src/queries/page-queries'

const PuxLandingPage: FunctionComponent<PuxLandingPageType> = ({
  data,
  pageContext,
}) => {
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData
  const breadCrumbsData = pageContext.breadcrumbs
  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
    // localizedPath={
    //   data.orchard.puxLandingPage[0].localization?.localizations
    // }
    >
      <Breadcrumbs showOverWidget={breadcrumbsOnTopImage(pageContext?.widgets?.flow)} items={breadCrumbsData} />
      <WidgetBuilder widgetBuilderData={pageContext?.widgets?.flow} />
    </Layout>
  )
}

export default PuxLandingPage

export function Head(props: HeadProps<never, IPageContext<PuxLandingPageType>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}