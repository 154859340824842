import { getBaseQuery } from "../../queries/base-queries"
import { PageType, getPageQuery } from '../../queries/page-queries'
import { getBuilderService } from "./puxBuilderService"
import { puxGraphqlHelper } from "./puxGraphqlHelper"
import { getLocalizedUrl } from '../localeURL'
import { getBreadcrumbs } from '../getBreadCrumbs'
import { IBuildTypedPages, IPageContext } from './buildTypedPage'
import { getWidgetsFromOrchardWidgetZoneES } from "./getWidgetsFromOrchardWidgetZone"

export const getTypedData = async (page: PageType, settings: IBuildTypedPages, isPreview: boolean | undefined = undefined) => {
    const { locale } = getBuilderService()
    const path = page.path

    if (!path.startsWith(locale.isoCode)) {
        console.log(
            `WARNING: Invalid path of node: ${path} ${page.contentItemId}`
        )
    }

    const context: Partial<IPageContext<PageType>> = {
        pagePath: path,
    }

    if (settings.template.widgetZones && settings.template.widgetZones.length) {
        context.widgets = {}
        if (isPreview === true) {
            const test = settings.template.widgetZones.map(async (widgetZone) => {
                return new Promise((resolve, reject) => {
                    const baseQueryWithoutMeta = getBaseQuery({
                        contentType: settings.template.contentType,
                        sections: [widgetZone],
                        addPuxCategoryToQuery: false,
                        addMetaTags: false,
                        customBreadCrumbsTax: undefined
                    })

                    getWidgetsFromOrchardWidgetZoneES({
                        zone: page[widgetZone],
                        baseQueryForWidget: baseQueryWithoutMeta,
                        path: path,
                        documentType: settings.template.contentType,
                        widgetZoneName: widgetZone,
                        isPreview: true,
                        versionId: page.contentItemVersionId
                    }).then(widgets => {
                        if (context.widgets) {
                            context.widgets[widgetZone] = widgets.map(
                                (widgetsGroup) => widgetsGroup[0]
                            )
                        }
                        resolve(true)
                    }).catch(error => reject(error))
                })
            })

            try {
                await Promise.all(test)
            } catch (error) {
                console.log(error)
            }
        } else {
            await settings.template.widgetZones.map(async (widgetZone) => {
                const baseQueryWithoutMeta = getBaseQuery({
                    contentType: settings.template.contentType,
                    sections: [widgetZone],
                    addPuxCategoryToQuery: false,
                    addMetaTags: false,
                    customBreadCrumbsTax: undefined
                })

                const widgets = await getWidgetsFromOrchardWidgetZoneES({
                    zone: page[widgetZone],
                    baseQueryForWidget: baseQueryWithoutMeta,
                    path: path,
                    documentType: settings.template.contentType,
                    widgetZoneName: widgetZone,
                    isPreview: false,
                    versionId: page.contentItemVersionId
                })

                if (context.widgets) {
                    context.widgets[widgetZone] = widgets
                }
            })
        }
    }

    const localizedPath = getLocalizedUrl(path)
    // context.isoCode = localizedConfigData.isoCode

    if (settings.addons.addMetaTags) {
        context.metaTags = page.puxMetaTags

        if (
            !context.metaTags?.metaTagsTitle.html ||
            !context.metaTags?.metaTagsTitle.html.length
        ) {
            context.metaTags.metaTagsTitle.html = page.displayText
        }

        if (
            !context.metaTags?.metaTagsCanonicalUrl ||
            !context.metaTags?.metaTagsCanonicalUrl.html ||
            !context.metaTags?.metaTagsCanonicalUrl.html.length
        ) {
            context.metaTags.metaTagsCanonicalUrl = {
                html: localizedPath,
            }
        }
    }

    if (settings.addons.addBreadCrumbs) {
        const customBreadcrumbsExist = settings.addons.customBreadCrumbsTax
            ? page[settings.addons.customBreadCrumbsTax] &&
            page[settings.addons.customBreadCrumbsTax].taxonomyContentItemId &&
            page[settings.addons.customBreadCrumbsTax].termContentItemIds[0]
            : false


        if (customBreadcrumbsExist && settings.addons.customBreadCrumbsTax) {
            const breadCrumbsData = await getBreadcrumbs(
                page[settings.addons.customBreadCrumbsTax],
                path,
                page.displayText
            )

            context.breadcrumbs = breadCrumbsData
        } else {
            const breadCrumbsData = await getBreadcrumbs(
                page.puxCategory,
                path,
                page.displayText
            )

            context.breadcrumbs = breadCrumbsData
        }
    }

    const pageDataQuery = getPageQuery(page.contentType, page.path, isPreview)
    if (pageDataQuery) {
        const result = await puxGraphqlHelper<PageType>(
            pageDataQuery,
            {
                errorMetadata: {
                    fromFunction: `Error while running GraphQL query for ${page.path} in buildTypedPage.ts`,
                    metadata: `Query: ${pageDataQuery}`
                }
            }
        )

        // change first char in string to lowercase
        const contentTypeCamelCase = page.contentType.charAt(0).toLowerCase() + page.contentType.slice(1)

        if (page.contentType === 'SalmonSoftwareInsight') {
            context.pageData = { ...result, contentItemId: page.contentItemId }
        } else {
            context.pageData = { ...result[contentTypeCamelCase][0], contentItemId: page.contentItemId }
        }
    }

    context.headerData = settings.layout.headerData
    context.footerData = settings.layout.footerData

    return {
        localizedPath,
        context,
    }
}