import { useLocation } from '@reach/router'
import React, { FunctionComponent, useEffect, useState } from 'react'

import Loader from '@componentsfld/core/Loader'
import { getLocalizedData } from '../utils/localeURL'
import { getBuilderService, initBuilderService } from '../utils/data/puxBuilderService'
import type { CreatePagesArgs, Reporter } from "gatsby"
import { getLocaleFromUrl, getPrefixedUrl } from '../utils/preview/previewURL'
import { fakeGraphql, fakeReporter, getAuthorizationToken, getPreviewData, getFakePageProps, getTemplateDataFromUrl } from '../utils/preview/getPreviewData'
import PuxLandingPage from '../templates/PuxLandingPage/PuxLandingPage'
import SalmonSoftwareContactPage from '../templates/SalmonSoftwareContactPage/SalmonSoftwareContactPage'
import SalmonSoftwareCategory from '../templates/SalmonSoftwareCategory/SalmonSoftwareCategory'
import SalmonSoftwareInsight from '../templates/SalmonSoftwareInsight/SalmonSoftwareInsight'
import SalmonSoftwareSearchResults from '../templates/SalmonSoftwareSearchResults/SalmonSoftwareSearchResults'

const PreviewPage: FunctionComponent = () => {
  const location = useLocation();

  if (!process.env.PROJECT) {
    return null
  }

  let localePrefix;

  if (process.env.LOCALE && ["cs", "en", "hu"].includes(process.env.LOCALE)) {
    localePrefix = process.env.LOCALE
  }

  const localizedConfigData = getLocalizedData(
    getLocaleFromUrl(location.pathname, "preview", localePrefix),
    process.env.PROJECT as string
  ) ?? getLocalizedData(process.env.LOCALE!, process.env.PROJECT!)

  const fakeBuilderArgs: Partial<CreatePagesArgs> = {
    graphql: fakeGraphql,
    reporter: new fakeReporter() as Reporter
  }
  initBuilderService(fakeBuilderArgs as CreatePagesArgs, localizedConfigData, false)

  const [state, setState] = useState<any>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getBuilderService()?.authorization.access_token ? true : false)

  useEffect(() => {
    const fetchToken = async () => await getAuthorizationToken()

    fetchToken().then(() => {
      if (getBuilderService().authorization.access_token) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    })
  })

  useEffect(() => {
    const cmsUrl = getPrefixedUrl(location.pathname, "preview", localePrefix)

    const fetchData = async (templateData, cmsUrl) => {
      return await getPreviewData({
        contentItemId: templateData.contentItemId ?? ``,
        contentItemVersionId: templateData.contentItemVersionId ?? ``,
        contentType: templateData.contentType ?? ``,
        path: cmsUrl ?? ``,
      })
    }

    if (isAuthenticated) {
      getTemplateDataFromUrl(cmsUrl).then((templateData) => {
        if (!templateData) {
          setState(undefined)
          return
        }

        fetchData(templateData, cmsUrl).then(async (pageData) => {
          setState({
            context: pageData?.context, pageType: templateData.contentType ?? ""
          })
        })
      })
    } else {
      setState({ context: undefined, pageType: undefined })
    }
  }, [isAuthenticated])

  function renderLogin() {
    return (
      <>
        <div className='u-marginTop120 u-textCenter'>
          <h1>Log in to your orchard account.</h1>
          <p className='u-marginTop80'>Are you logged in? Please <a href={location.href}>refresh this page 🔃</a>.</p>
        </div>
      </>
    )
  }

  function renderMissingTemplate() {
    return (
      <>
        <div className='u-marginTop120 u-textCenter'>
          <h1>Missing Template.</h1>
          <p className='u-marginTop80'>This page does not have preview feature. <br />Contact Dev Team.</p>
        </div>
      </>
    )
  }

  function renderPreview() {
    const pageProps = getFakePageProps(location, state?.context)
    if (state) {
      switch (state.pageType) {
        case `PuxLandingPage`:
          return (
            <PuxLandingPage
              {...pageProps}
            />
          )
        case `SalmonSoftwareCategory`:
          return (
            <SalmonSoftwareCategory
              {...pageProps}
            />
          )
        case `SalmonSoftwareContactPage`:
          return (
            <SalmonSoftwareContactPage
              {...pageProps}
            />
          )
        case `SalmonSoftwareInsight`:
          return (
            <SalmonSoftwareInsight
              {...pageProps}
            />
          )
        case `SalmonSoftwareSearchResults`:
          return (
            <SalmonSoftwareSearchResults
              {...pageProps}
            />
          )
        case undefined:
          return <Loader message='Loading Preview Mode' />
        default:
          return renderMissingTemplate()
      }
    }

    return <Loader message='Loading Preview Mode' />
  }

  if (!isAuthenticated) {
    return renderLogin()
  }

  return <div>{renderPreview()}</div>
}

const Previews = () => (
  <PreviewPage />
)

export default Previews
