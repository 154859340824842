import "./SalmonSoftwareCategory.scss";

import React, { FunctionComponent } from "react";
import Layout from "../../components/core/Layout";
import WidgetBuilder from "../../components/builders/WidgetBuilder";
import SalmonSoftwareCategorySearchBanner from "../../components/contentTypes/SalmonSoftwareCategorySearchBanner/SalmonSoftwareCategorySearchBanner";
import Breadcrumbs from "../../components/core/Breadcrumbs";
import { HeadProps } from "gatsby";
import { IPageContext } from "Root/src/utils/data/buildTypedPage";
import PuxMetaTagsGatsby from "Root/src/utils/PuxMetaTagsGatsby";
import { CategoryPageType, SalmonSoftwareCategoryType } from "Root/src/queries/page-queries";

const hasSearchBanner = (categoryData: CategoryPageType): boolean => {
  if (categoryData.categoryContent?.html && categoryData.categoryImage?.mediaSelectorImage?.resizePaths[0]) {
    return true
  }

  return false
}

const SalmonSoftwareCategory: FunctionComponent<SalmonSoftwareCategoryType> = ({
  data,
  pageContext,
}) => {
  const category = pageContext.pageData;
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData

  const searchBannerHandler = (value: string) => {
    if (typeof document !== undefined) {
      const url = new URL(location.href)
      url.searchParams.delete("searchTerm")
      url.searchParams.set("searchTerm", value)

      location.href = url.origin + "/search-results/" + url.search
    }
  }

  return (
    <Layout
      footerData={footerData}
      headerData={headerData}
    >
      <div className={`Category`}>
        <Breadcrumbs showOverWidget={hasSearchBanner(category)} items={pageContext.breadcrumbs} />
        {hasSearchBanner(category) && (
          <SalmonSoftwareCategorySearchBanner
            searchBannerHandler={searchBannerHandler}
            searchBannerImage={category.categoryImage}
            searchBannerContent={category.categoryContent}
          />
        )}
        <WidgetBuilder widgetBuilderData={pageContext?.widgets?.flow} />
      </div>
    </Layout>
  );
};

export default SalmonSoftwareCategory;

export function Head(props: HeadProps<never, IPageContext<SalmonSoftwareCategoryType>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
