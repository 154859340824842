export const getHeaderQuery = (isoCode: string) => {
  return `
  {
    orchard {
      menu(where: { alias: { alias_ends_with: "/header-navigation" }, localization: {culture: "${isoCode.toLowerCase()}"} }) {
        displayText
        alias {
          alias
        }
        menuLinkedinLink
        menuXLink
        menuXingLink
        menuItemsList {
          menuItems {
            ... on Orchard_ContentMenuItem {
              render
              contentMenuItemCTA {
                html
              }
              contentMenuItemLinkText
              contentMenuItemImage {
                resizePaths(width: 1920, mode: "Min", quality: 60)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
              }
              contentMenuItemIsButton
              menuItemsList {
                menuItems {
                  ... on Orchard_PuxMegamenuCategoryItem {
                    categoryItemDescription
                    categoryItemName
                    menuItemsList {
                      menuItems {
                        ... on Orchard_ContentMenuItem {
                          contentType
                          render
                          contentMenuItemCTA {
                            html
                          }
                          contentMenuItemLinkText
                          contentMenuItemImage {
                            resizePaths(width: 1920, mode: "Min", quality: 60)
                            resizePathsMobile(width: 768, mode: "Min", quality: 75)
                            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                            texts
                          }
                        }
                        ... on Orchard_LinkMenuItem {
                          contentType
                          linkMenuItem {
                            name
                            url
                          }
                        }
                        ... on Orchard_HtmlMenuItem {
                          htmlMenuItem {
                            html
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on Orchard_LinkMenuItem {
              contentType
              linkMenuItem {
                name
                url
              }
            }
          }
        }
      }
    }
  }
  `
}

export const getFooterQuery = (isoCode: string) => {
  return `
  {
    orchard {
      salmonSoftwareFooter(where: {localization: {culture: "${isoCode.toLowerCase()}"}}) {
          footerContent {
            html
          }
          footerSocials {
            contentItems {
              contentType
              ... on Orchard_SalmonSoftwareSocial {
                displayText
                socialType
                socialLink
              }
            }
          }
          footerMenu {
            contentItems {
              ... on Orchard_SalmonSoftwareUrlItem {
                displayText
                contentType
                urlItemLink
              }
              ... on Orchard_SalmonSoftwareLinkItem {
                displayText
                contentType
                linkItemLink {
                  contentItems {
                    ... on Orchard_PuxLandingPage {
                      path
                    }
                    ... on Orchard_SalmonSoftwareCategory {
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export interface IFooterData {
  salmonSoftwareFooter: FooterData[];
}

export interface FooterData {
  footerContent: FooterContent;
  footerMenu: FooterMenu;
  footerSocials: FooterSocials;
}

interface FooterMenu {
  contentItems: FooterMenuItem[];
}

interface FooterMenuItem {
  displayText: string;
  contentType: string;
  linkItemLink: {
    contentItems: FooterMenuItemLinks[]
  }
}

interface FooterMenuItemLinks {
  path: string;
}

interface FooterSocials {
  contentItems: FooterSocialItem[];
}

interface FooterSocialItem {
  contentType: string;
  displayText: string;
  socialType: string;
  socialLink: string;
}

interface FooterContent {
  html: string;
}

export interface IHeaderData {
  menu: HeaderMenu[];
}

interface HeaderMenu {
  menuItemsList: HeaderMenuItemsList;
}

interface HeaderMenuItemsList {
  menuItems: HeaderMegamenuItem[];
}

interface HeaderMegamenuItem {
  render: string;
  contentMenuItemDuplicateAlias: null | string;
  menuItemsList: HeaderCategoryMenuItemsList | null;
}

interface HeaderCategoryMenuItemsList {
  menuItems: HeaderCategoryMenuItem[] | HeaderColumnItem[];
}

interface HeaderCategoryMenuItem {
  categoryItemDescription: null | string;
  categoryItemColumns: null | string;
  categoryItemName: string;
  menuItemsList: HeaderSubMenuItemsList;
}

interface HeaderColumnItem {
  contentType: "PuxMegamenuColumn";
  menuItemsList: HeaderCategoryMenuItemsList;
}

interface HeaderSubMenuItemsList {
  menuItems: HeaderSubMenuItem[];
}

interface HeaderSubMenuItem {
  contentMenuItemDuplicateAlias?: null;
  contentMenuItemDuplicateMainItem?: boolean;
  contentMenuItemDuplicateHide?: boolean | null;
  contentMenuItemArrow?: boolean;
  contentMenuItemDescription?: string;
  contentMenuItemMediaField?: {
    resizePaths: [string]
    type: [string]
  };
  linkMenuItemArrow?: boolean;
  linkMenuItemDescription?: string;
  linkMenuItemMediaField?: {
    resizePaths: [string]
    type: [string]
  };
  contentType: "ContentMenuItem" | "LinkMenuItem";
  render?: string;
  linkMenuItem?: LinkMenuItem;
}

interface LinkMenuItem {
  name: string;
  url: string;
}