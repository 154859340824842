import { getWidgetQuery } from "../../queries/widget-queries"
import { WidgetPuxSectionType } from "../../components/widgets/WidgetPuxSection"
import { getBuilderService } from "./puxBuilderService"
import { puxGraphqlHelper } from "./puxGraphqlHelper"
import { PageType, PageContentType } from "../../queries/page-queries"
import { getRepeaterData } from "./getRepeaterData"
import { WidgetData } from "Root/src/components/builders/WidgetBuilder"

type IWidgetZoneItem<T> = { [key: string]: T[] }

export interface IGetWidgetsFromOrchardWidgetZone {
    zone: IWidgetZoneItem<WidgetPuxSectionType> | null
    baseQueryForWidget: string
    path: string
    documentType: PageContentType
    widgetZoneName: string
    isPreview?: boolean
    versionId?: string
}

export const getWidgetsFromOrchardWidgetZoneES = async ({
    zone,
    baseQueryForWidget,
    path,
    documentType,
    widgetZoneName = `flow`,
    isPreview = false,
    versionId = undefined,
}: IGetWidgetsFromOrchardWidgetZone): Promise<WidgetPuxSectionType[]> => {
    const { locale } = getBuilderService()
    // change first char in string to lowercase
    const documentTypeCamelCase = documentType.charAt(0).toLowerCase() + documentType.slice(1)


    if (zone === null) {
        zone = { widgets: [] }
    }

    const dataTemplate = {}
    zone.widgets.map((section) => {
        if (section.contentItemId) {
            dataTemplate[section.contentItemId] = []
            section.flow.widgets.map((widget) => {
                if (section.contentItemId && dataTemplate[section.contentItemId].indexOf(widget.contentType) < 0) {
                    dataTemplate[section.contentItemId].push(widget.contentType)
                }
            })
        }
    })

    return Promise.all(
        Object.keys(dataTemplate).map(async (sectionID) => {
            const sectionObject = Object.assign(
                {},
                zone?.widgets.filter((sectionWidget) => {
                    return sectionWidget.contentItemId === sectionID
                })[0]
            )

            const templates = zone?.widgets.filter(
                (template) => {
                    return template?.contentItemId === sectionObject?.contentItemId
                }
            )

            sectionObject.template = templates[0].flow.widgets

            sectionObject.flow.widgets = []

            if (dataTemplate[sectionID].length) {
                if (isPreview === true) {
                    return Promise.all(
                        dataTemplate[sectionID].map(async (widgetFragment) => {
                            return new Promise(async (resolve) => {
                                const fragment = getWidgetQuery(widgetFragment, "")
                                if (fragment === ``) {
                                    return
                                }

                                const widgetQuery = baseQueryForWidget
                                    .replace(/%baseSectionQueryContentType%/g, ``)
                                    .replace(/%widgetFragmentsPlaceholder%/g, fragment)
                                    .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: ALL," : ""} where: {path: "${path}"${versionId ? ", contentItemVersionId: \"" + versionId + "\"" : ""}})`)

                                const widgetNode = await puxGraphqlHelper<PageType>(widgetQuery, {
                                    errorMetadata:
                                    {
                                        message: `Error while running GraphQL query for widgetFragment ${widgetFragment}`,
                                        metadata: `Query: ${widgetQuery}`
                                    }
                                })

                                if (widgetNode) {
                                    widgetNode[documentTypeCamelCase][0][widgetZoneName].widgets
                                        .filter((section) => section.contentItemId === sectionID)[0]
                                        .flow.widgets.filter((widget) => Object.keys(widget).length > 0)
                                        .map(async (widget: WidgetData) => {
                                            if (
                                                widget.contentType === `PuxRepeater` &&
                                                widget.puxFilter
                                            ) {
                                                const repData = await getRepeaterData(widget)
                                                const repWid = { ...repData }
                                                sectionObject.flow.widgets.push(repWid as WidgetData)
                                            } else {
                                                sectionObject.flow.widgets.push(widget)
                                            }
                                            // PuxRepeater - end
                                            // todo: resolve az za map
                                            resolve(sectionObject)
                                        })
                                } else {
                                    resolve(sectionObject)
                                }
                            })
                        })
                    )
                } else {
                    dataTemplate[sectionID].map(async (widgetFragment) => {
                        const fragment = getWidgetQuery(widgetFragment, "")
                        if (fragment === ``) {
                            return
                        }

                        const widgetQuery = baseQueryForWidget
                            .replace(/%baseSectionQueryContentType%/g, ``)
                            .replace(/%widgetFragmentsPlaceholder%/g, fragment)
                            .replace(/%pathCondition%/g, `(first: 1,${isPreview ? " status: ALL," : ""} where: {path: "${path}"})`)

                        const widgetNode = await puxGraphqlHelper<PageType>(widgetQuery, {
                            errorMetadata:
                            {
                                message: `Error while running GraphQL query for widgetFragment ${widgetFragment}`,
                                metadata: `Query: ${widgetQuery}`
                            }
                        })

                        if (widgetNode) {
                            widgetNode[documentTypeCamelCase][0][widgetZoneName].widgets
                                .filter((section) => section.contentItemId === sectionID)[0]
                                .flow.widgets.filter((widget) => Object.keys(widget).length > 0)
                                .map(async (widget: WidgetData) => {
                                    if (
                                        widget.contentType === `PuxRepeater` &&
                                        widget.puxFilter
                                    ) {
                                        const repWid = { ...await getRepeaterData(widget) }
                                        sectionObject.flow.widgets.push(repWid as WidgetData)
                                    } else {
                                        sectionObject.flow.widgets.push(widget)
                                    }
                                })
                        }
                    })

                    return sectionObject
                }
            } else {
                if (isPreview === true) {
                    return new Promise(async (resolve) => {
                        resolve(sectionObject)
                    })
                }
            }
        })
    )
}